<template>
	<div class="loader-overlay">
		<div class="bars-loader">
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
