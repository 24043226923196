<template>
    <form @submit.prevent="handleLogin()">
        <div v-if="loginError" class="error-msg">
            <p>Your username and/or password is not correct</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-email">E-mail</label>
                <div class="input-wrapper">
                    <input type="email" id="user-email" name="username" placeholder="E-mail" @keyup="changeInput()"
                        v-model="user.username" :class="{ error: loginError }" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-password">Password</label>
                <div class="input-wrapper">
                    <input type="password" id="user-password" name="password" placeholder="Password" @keyup="changeInput()"
                        v-model="user.password" :class="{ error: loginError }" />
                </div>
            </span>
        </div>

        <div class="form-row last-row">
            <span class="field-wrapper checkbox">
                <div class="input-wrapper">
                    <input type="checkbox" id="user-login-rememberme" name="user-login-rememberme" />
                    <label for="user-login-rememberme">Remember me</label>
                </div>
            </span>
            <span class="field-wrapper btn">
                <button type="button" @click="forgotPassword" class="regular">
                    Forgot password?
                </button>
            </span>
        </div>

        <button class="submit" data-txt="Login">Login</button>
    </form>
</template>

<script>
// @import utils
import { authenticateUser } from "@streampac.io/chef_sp_1";

import themeFunctions from "@/functions.js";

export default {
    inject: ["setAuthenticated"],
    name: "LoginForm",
    components: {},
    data() {
        return {
            user: {
                username: "",
                password: "",
            },
            loginError: "",
            checkedRememberMe: null,
        };
    },
    methods: {
        forgotPassword() {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("showPasswordPopup");
        },
        changeInput() {
            this.loginError = "";
        },
        handleLogin() {
            let userStr = this.user.username;

            if (
                this.user.username &&
                this.user.username == "admin@streampac.com"
            ) {
                userStr = "admin";
            }

            authenticateUser(userStr, this.user.password, (response) => {
                //Login failed
                if (response.status == 400 || response.succes == false) {
                    this.setAuthenticated(false);
                    this.loginError = true;
                    this.user.username = "";
                    this.user.password = "";
                } else {
                    this.setAuthenticated(true);
                    this.loginError = false;
                    this.$store.commit("storeSetAuthenticated");

                    if (this.$route.name == "RegisterPage") {
                        this.$router.push("/memberships");
                    }

                    if (this.$route.name !== "memberships") {
                        //reload current URL -> get new info based on auth
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                    }

                    setTimeout(() => {
                        themeFunctions.saveOrderDataInStore(
                            this,
                            this.isAuthenticated
                        );
                    }, 200);
                }
            });
        },
    },
};
</script>
