<template>
    <section class="login-popup global-popup">
        <div class="container">
            <div class="column-wrapper">
                <div class="column login-form">
                    <Loader v-if="showLoaderOverlay" />
                    <div class="title-wrapper">
                        <h2 class="column-title">Login</h2>
                        <p class="subtitle">
                            Welcome back to LEAD Field Hockey
                        </p>
                    </div>
                    <LoginForm @showLoader="changeLoaderVisibility" />
                    <button class="close-btn" @click="hidePopups"></button>
                </div>
                <div class="column register-cta">
                    <div class="title-wrapper">
                        <h2 class="column-title">Get started</h2>
                        <p class="subtitle">
                            Learn, improve, lead
                        </p>

                    </div>
                    <ul class="usp">
                        <li>Technical skills</li>
                        <li>Tactical classses</li>
                        <li>Exercises</li>
                    </ul>

                    <router-link v-if="this.$route.name != 'waitlist'" class="button" to="/contact">Contact us</router-link>
                    <button v-if="this.$route.name == 'waitlist'" class="button" @click="hidePopups">
                        Contact us
                    </button>
                    <!-- <button v-if="this.$route.name == 'RegisterPage'" class="button" data-txt="Sign up today!"
                        @click="hidePopups">
                        Sign up today!
                    </button> -->
                </div>
            </div>
        </div>

        <div class="background-el" @click="hidePopups"></div>
    </section>
</template>

<script>
// @import component
import LoginForm from "@/components/Forms/LoginForm";
import Loader from "@/components/Loader";

export default {
    name: "LoginPopup",
    data() {
        return {
            showLoaderOverlay: false,
        };
    },
    components: {
        LoginForm,
        Loader,
    },
    created() { },
    methods: {
        changeLoaderVisibility($value) {
            if ($value == true) {
                this.showLoaderOverlay = true;
            } else {
                this.showLoaderOverlay = false;
            }
        },
        hidePopups() {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("hidePasswordPopup");
        },
    },
};
</script>
